import axios from 'axios';
import React, { useEffect,useState } from 'react'
import Constants from '../../../Constants';
import Swal from 'sweetalert2'
import Nav from '../../layout/Nav';
import SideBar from '../../layout/SideBar';
import Footer from '../../layout/Footer';
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/creatable';


export default function OverseaseMediclaimPolicyNew() {

  const [input,setInput] = useState(
    {
      Action:1,
      FinalMRKey: 0,
      FinalMRKeyREF: 0,
      BranchKey:21,
      Class_Name: 'Miscellaneous',
      Sub_Class_Name: 'Overseas Mediclaim',
      YearName: new Date().getFullYear(),
      MRType : '',
      MRType_2: '',
      MRType_3 : '',
      MRType_4: '',
      MRCode: 0,
      MRCode_Dis: '',
      MRDate: null,
      DocCode: 0,
      DocNo: '',
      DocDate: new Date().toISOString().substring(0, 10),        
      CoverNoteNo: '',
      CNDate: null,
      PolicyNo: '',
      PODate: null,
      CoIns: false,
      ComKeyCoIns : 0,
      LeaderDocNo: '',
      CoInsPer : 0,
      We_Leader: false,
      SumInsured: 0,
      SumInsuredCoIns:0,
      MRNetPremium: 0,
      NetPremium:0,
      VatPer: 15,
      VatAmount: 0,
      StampDuty:0,
      OthersAmount:0,
      Ref_SumInsured:0,
      Ref_SumInsuredCoIns :0,
      Ref_NetPremium:0,
      Ref_VatAmount :0,
      Ref_StampDuty:0,
      Ref_ChargeAmount:0,
      Ref_DocNo:'',
      Ref_CoInsSumInsured :0,
      Ref_CoInsNetPremium:0,
      Active: true,
      DepositDate: null,
      Depo_NetPremium:0,
      Depo_NetPremium_CoIns :0,
      Depo_VatAmount: 0,
      Depo_StampDuty: 0,
      MR_Allowable: 0,
      Business: 0,
      ClientKey: 0,
      BankKey:0,
      ClientKey_Old: 0,
      BankKey_Old: 0,
      EmpKey: 0,
      AgentKey: 0,
      PeriodFrom:new Date().toISOString().substring(0, 10),
      PeriodTo: new Date().toISOString().substring(0, 10),
      
      
      Bank_Guarantee:0,        
      Coll_Our_Share: '',
      NewClient: 0,
      WithChargeAmount: 0,
      DocCancel: false,
      DocCancelDate: null,
      NotUtilized: false,
      DocEdit: false,
      ReinsuranceAmount: 0,
      ClaimAmount: 0,
      VoucherKey: 0,
      HO: false,
      Pay_Status: false,
      BoughKey: 0,
      TargetKey: 0,
      Remarks: '',
      PaymentType:'',
      CurrencyName: '',
      FCurrText: '',
      AmountInWord: '',
      BankName:'',
      BranchName:'',
      ChequeNo: '',
      ChequeDate: null,
      ChargeAmount: 0,

      Text_Field_1:'',
      Text_Field_2:'',
      Text_Field_3:'',
      Text_Field_4:'',
      Text_Field_5:'',
      Text_Field_6:'',
      Text_Field_7:'',
      Text_Field_8:'',
      Text_Field_9:'',
      Text_Field_10:'',
      Text_Field_11:'',
      Text_Field_12:'',
      Text_Field_13:'',
      Text_Field_14:'',
      Text_Field_15:'',
      Num_Field_1: 0,
      Num_Field_2:0,
      Num_Field_3:0,
      Num_Field_4: 0,
      Num_Field_5: 0,
      Num_Field_6: 0,
      Num_Field_7: 0,
      Num_Field_8: 0,
      Num_Field_9: 0,
      Num_Field_10: 0,
      Num_Field_11: 0,
      Num_Field_12: 0,
      Num_Field_13: 0,
      Num_Field_14: 0,
      Num_Field_15: 0,

      Date_Field_1: null,

      LockData: true,



    }    
  );

  //tariff 
  const [mediclaimTariff, setMediclaimTariff] = useState({
    "OTIDKey": 0,
    "Days_From": 0,
    "Days_To": 0,
    "Age_From": 0,
    "Age_To": 0,
    "Rate": 0,
    "PlanType": "",
    "Tariff_Type": 0,
    "Travel_Type" : 0
  });

  

  const handleInput=(e)=>{
    console.log(e);

    if(e.target.type === 'checkbox')
      {
        setInput( prevState=>({...prevState,[e.target.name]: e.target.checked}))
      }
      else
      {
          setInput( prevState=>({...prevState,[e.target.name]: e.target.value}))
      }

      console.log(input);
      
  }

  const handleTariffSelect=(e)=>{
    
    if(e.target.name === 'MRType')
    {      
      setMediclaimTariff( prevState=>({...prevState,["Travel_Type"]: e.target.value}));
      setInput( prevState=>({...prevState,[e.target.name]: e.target.options[e.target.selectedIndex].text}));      
    }
    else if(e.target.name === 'MRType_2')
    {
      console.log(e.target.options[e.target.selectedIndex].text);
      setMediclaimTariff( prevState=>({...prevState,["Tariff_Type"]: e.target.value}));
      setInput( prevState=>({...prevState,[e.target.name]: e.target.options[e.target.selectedIndex].text}));
    }
    else if(e.target.name === 'Num_Field_1')
    {
      setMediclaimTariff( prevState=>({...prevState,"Days_From": parseInt(e.target.value)}));
      setInput( prevState=>({...prevState,"Num_Field_1": parseInt( e.target.value)}));
    }
    else if(e.target.name === 'Date_Field_1')
    {

      var date2 = new Date();
      var date1 = new Date(e.target.value);
      var diffYears = parseInt((date2 - date1) / (1000 * 60 * 60 * 24*365), 10); 

       
      setMediclaimTariff( prevState=>({...prevState,"Age_From": diffYears}));
      setInput( prevState=>({...prevState,"Date_Field_1": e.target.value, "Num_Field_2":diffYears}));
      console.log(diffYears);
    }
   
    
    
  }

  const changePeriodTo=()=>{
    
    if( parseInt(input.Num_Field_1) >=0 )
    {
        var today = new Date(input.PeriodFrom);
        console.log(input.PeriodFrom);
        console.log(today);
        var endDate = new Date();
        endDate.setDate(today.getDate()+ input.Num_Field_1);
        endDate = endDate.toISOString().substring(0, 10)
        setInput( prevState=>({...prevState,"PeriodTo": endDate }));
    }   

  }

  const loadRate=()=>{
    
    
    axios.post(`${Constants.BASE_URL}/MediclaimTariff/GetTravelRate`,mediclaimTariff)
    .then(res=> {                     
                      
          if(res.data[0].Rate>0)
          { 
            var tmpRate = res.data[0].Rate;
            setInput( prevState=>({...prevState,'NetPremium': res.data[0].Rate}));

            var vat = Math.round( tmpRate * input.VatPer / 100); // 
            setInput( prevState=>({...prevState, 'VatAmount': vat}));
      
            var grandTotal = Math.round( tmpRate + vat );
            setInput( prevState=>({...prevState, 'MRNetPremium': grandTotal})); //MRNetPremium = Grand Total

          }
          else
          {
            setInput( prevState=>({...prevState,'NetPremium': 0}));

            var tmpRate = 0;
            setInput( prevState=>({...prevState,'NetPremium': res.data[0].Rate}));

            var vat = Math.round( tmpRate * input.VatPer / 100); // 
            setInput( prevState=>({...prevState, 'VatAmount': vat}));
      
            var grandTotal = Math.round( tmpRate + vat );
            setInput( prevState=>({...prevState, 'MRNetPremium': grandTotal})); //MRNetPremium = Grand Total
          }

          
        }       
        
      ) 
      .catch(function(error){
        setInput( prevState=>({...prevState,'NetPremium': 0}));
        setInput( prevState=>({...prevState, 'VatAmount': 0}));
        setInput( prevState=>({...prevState, 'MRNetPremium': 0}));

      }
      )         

  }

  useEffect(() => {
    loadRate();    
    
}, [mediclaimTariff]); 

useEffect(() => {
  changePeriodTo();    
  
}, [input.PeriodFrom, input.Num_Field_1]); 


const showToast=(msg)=>{    

  toast.error(msg, {
    position: toast.POSITION.TOP_LEFT,
    autoClose: false,
  });

}

const validateData = ()=>{
  
  var isValidate = true;
  toast.dismiss();
  if(input.MRType === "Select")
  {
    showToast("Select Insurance Sub Type.");
    isValidate = false;
  }
  else if(input.MRType_2 === 'Select')
  {
    showToast("Select OMP ");
    isValidate = false;
  } 
  else if(input.Num_Field_1 ===0 )
  {
    showToast("Input Travel Days.");
    isValidate = false;

  }
  else if(input.Date_Field_1 === null)
  {
    showToast("Date of Birth.");
    isValidate = false;
  }
  else if(input.Text_Field_1 === '')
  {
    showToast("Select Gender.");
    isValidate = false;
  }
  else if(input.Text_Field_2 === '')
  {
    showToast("First name");
    isValidate = false;
  }
  else if(input.Text_Field_4 === '')
  {
    showToast("Father's Name");
    isValidate = false;
  }
  else if(input.Text_Field_5 === '')
  {
    showToast("Address");
    isValidate = false;
  }
  else if(input.Text_Field_6 === '')
  {
    showToast('Input mobile no.');
    isValidate = false;
  }
  else if(input.Text_Field_9 === '')
  {
    showToast('Input Passport No.');
    isValidate = false;
  }
  else if (input.NetPremium === 0)
  {
    showToast('No premium for your selection. Contact our office.');
    isValidate = false;
  }
  else if (input.Text_Field_11 === '')
  {
    showToast('Select a country.');
    isValidate = false;
  }
  
  return isValidate;
}

const SaveData = (e) =>
  { 
    var returnData = validateData();
    console.log(input);
    if(returnData === true)
    {
      if(input.Action===1)
      {
        axios.post(`${Constants.BASE_URL}/FinalMR/Create`,input).then(res=>{
      
          if(res.status === 200)
          { 
            //console.log(res.data);         
            setInput(res.data);
            setInput( prevState=>({...prevState,'Action': 2}));
            Swal.fire(
              'Good job!',
              'Data is saved.',
              'success'
            )

            var obj = {FinalMRKey: input.FinalMRKey}
            axios.get(`${Constants.BASE_URL}/SSLPayment/Checkout/`+ res.data.FinalMRKey)
            .then(resSSL=>{
                    
                    console.log(resSSL.data);  
                    window.location.replace(resSSL.data);                  
                    //window.open(resSSL.data);
                }
            )
            
          }
            
        })
        .catch(function(error){
          Swal.fire(
            error.response.data,
            error.message,
            'error'
          )

          
        }
        )    

      }
      
    }
  }

  const PayNow=(e)=>{

    console.log(input);
    if(input.FinalMRKey >0)
    {

    }
  }

  const DownloadFile =(e)=>{

    var obj = {FinalMRKey: input.FinalMRKey}
    axios.get(`${Constants.BASE_URL}/Bank/RepoetOMP/`+obj.FinalMRKey )
        .then(res=>{
                            
                var fileURL = `${Constants.WEB_ROOT_PATH}/ReportsDownload/`+ res.data.FileName;
                window.open(fileURL);

            }
        )
        .catch(function(error) {

            
              Swal.fire(
                'Opps! Something wrong',
                error.message,
                'error'
              )
            
        
            console.log(error);
            });
}

// handle selection
const handleChange = e => {
  if(e != null)
  {
    setInput( prevState=>({...prevState,"Text_Field_11": e.value}))
  }
  else
  {
    setInput( prevState=>({...prevState,"Text_Field_11": ""}))
  }
  
  
  
}

const colourOptions = [
  { label: 'Afghanistan', value: 'Afghanistan' },
{ label: 'Albania', value: 'Albania' },
{ label: 'Algeria', value: 'Algeria' },
{ label: 'Andorra', value: 'Andorra' },
{ label: 'Angola', value: 'Angola' },
{ label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
{ label: 'Argentina', value: 'Argentina' },
{ label: 'Armenia', value: 'Armenia' },
{ label: 'Australia', value: 'Australia' },
{ label: 'Austria', value: 'Austria' },
{ label: 'Austrian Empire*', value: 'Austrian Empire*' },
{ label: 'Azerbaijan', value: 'Azerbaijan' },
{ label: 'Baden*', value: 'Baden*' },
{ label: 'Bahamas, The', value: 'Bahamas, The' },
{ label: 'Bahrain', value: 'Bahrain' },
{ label: 'Bangladesh', value: 'Bangladesh' },
{ label: 'Barbados', value: 'Barbados' },
{ label: 'Bavaria*', value: 'Bavaria*' },
{ label: 'Belarus', value: 'Belarus' },
{ label: 'Belgium', value: 'Belgium' },
{ label: 'Belize', value: 'Belize' },
{ label: 'Benin (Dahomey)', value: 'Benin (Dahomey)' },
{ label: 'Bolivia', value: 'Bolivia' },
{ label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
{ label: 'Botswana', value: 'Botswana' },
{ label: 'Brazil', value: 'Brazil' },
{ label: 'Brunei', value: 'Brunei' },
{ label: 'Brunswick and Lüneburg*', value: 'Brunswick and Lüneburg*' },
{ label: 'Bulgaria', value: 'Bulgaria' },
{ label: 'Burkina Faso (Upper Volta)', value: 'Burkina Faso (Upper Volta)' },
{ label: 'Burma', value: 'Burma' },
{ label: 'Burundi', value: 'Burundi' },
{ label: 'Cabo Verde', value: 'Cabo Verde' },
{ label: 'Cambodia', value: 'Cambodia' },
{ label: 'Cameroon', value: 'Cameroon' },
{ label: 'Canada', value: 'Canada' },
{ label: 'Cayman Islands, The', value: 'Cayman Islands, The' },
{ label: 'Central African Republic', value: 'Central African Republic' },
{ label: 'Central American Federation*', value: 'Central American Federation*' },
{ label: 'Chad', value: 'Chad' },
{ label: 'Chile', value: 'Chile' },
{ label: 'China', value: 'China' },
{ label: 'Colombia', value: 'Colombia' },
{ label: 'Comoros', value: 'Comoros' },
{ label: 'Congo Free State, The*', value: 'Congo Free State, The*' },
{ label: 'Costa Rica', value: 'Costa Rica' },
{ label: 'Cote d’Ivoire (Ivory Coast)', value: 'Cote d’Ivoire (Ivory Coast)' },
{ label: 'Croatia', value: 'Croatia' },
{ label: 'Cuba', value: 'Cuba' },
{ label: 'Cyprus', value: 'Cyprus' },
{ label: 'Czechia', value: 'Czechia' },
{ label: 'Czechoslovakia*', value: 'Czechoslovakia*' },
{ label: 'Democratic Republic of the Congo', value: 'Democratic Republic of the Congo' },
{ label: 'Denmark', value: 'Denmark' },
{ label: 'Djibouti', value: 'Djibouti' },
{ label: 'Dominica', value: 'Dominica' },
{ label: 'Dominican Republic', value: 'Dominican Republic' },
{ label: 'Duchy of Parma, The*', value: 'Duchy of Parma, The*' },
{ label: 'East Germany (German Democratic Republic)*', value: 'East Germany (German Democratic Republic)*' },
{ label: 'Ecuador', value: 'Ecuador' },
{ label: 'Egypt', value: 'Egypt' },
{ label: 'El Salvador', value: 'El Salvador' },
{ label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
{ label: 'Eritrea', value: 'Eritrea' },
{ label: 'Estonia', value: 'Estonia' },
{ label: 'Eswatini', value: 'Eswatini' },
{ label: 'Ethiopia', value: 'Ethiopia' },
{ label: 'Federal Government of Germany (1848-49)*', value: 'Federal Government of Germany (1848-49)*' },
{ label: 'Fiji', value: 'Fiji' },
{ label: 'Finland', value: 'Finland' },
{ label: 'France', value: 'France' },
{ label: 'Gabon', value: 'Gabon' },
{ label: 'Gambia, The', value: 'Gambia, The' },
{ label: 'Georgia', value: 'Georgia' },
{ label: 'Germany', value: 'Germany' },
{ label: 'Ghana', value: 'Ghana' },
{ label: 'Grand Duchy of Tuscany, The*', value: 'Grand Duchy of Tuscany, The*' },
{ label: 'Greece', value: 'Greece' },
{ label: 'Grenada', value: 'Grenada' },
{ label: 'Guatemala', value: 'Guatemala' },
{ label: 'Guinea', value: 'Guinea' },
{ label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
{ label: 'Guyana', value: 'Guyana' },
{ label: 'Haiti', value: 'Haiti' },
{ label: 'Hanover*', value: 'Hanover*' },
{ label: 'Hanseatic Republics*', value: 'Hanseatic Republics*' },
{ label: 'Hawaii*', value: 'Hawaii*' },
{ label: 'Hesse*', value: 'Hesse*' },
{ label: 'Holy See', value: 'Holy See' },
{ label: 'Honduras', value: 'Honduras' },
{ label: 'Hungary', value: 'Hungary' },
{ label: 'Iceland', value: 'Iceland' },
{ label: 'India', value: 'India' },
{ label: 'Indonesia', value: 'Indonesia' },
{ label: 'Iran', value: 'Iran' },
{ label: 'Iraq', value: 'Iraq' },
{ label: 'Ireland', value: 'Ireland' },
{ label: 'Israel', value: 'Israel' },
{ label: 'Italy', value: 'Italy' },
{ label: 'Jamaica', value: 'Jamaica' },
{ label: 'Japan', value: 'Japan' },
{ label: 'Jordan', value: 'Jordan' },
{ label: 'Kazakhstan', value: 'Kazakhstan' },
{ label: 'Kenya', value: 'Kenya' },
{ label: 'Kingdom of Serbia/Yugoslavia*', value: 'Kingdom of Serbia/Yugoslavia*' },
{ label: 'Kiribati', value: 'Kiribati' },
{ label: 'Korea', value: 'Korea' },
{ label: 'Kosovo', value: 'Kosovo' },
{ label: 'Kuwait', value: 'Kuwait' },
{ label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
{ label: 'Laos', value: 'Laos' },
{ label: 'Latvia', value: 'Latvia' },
{ label: 'Lebanon', value: 'Lebanon' },
{ label: 'Lesotho', value: 'Lesotho' },
{ label: 'Lew Chew (Loochoo)*', value: 'Lew Chew (Loochoo)*' },
{ label: 'Liberia', value: 'Liberia' },
{ label: 'Libya', value: 'Libya' },
{ label: 'Liechtenstein', value: 'Liechtenstein' },
{ label: 'Lithuania', value: 'Lithuania' },
{ label: 'Luxembourg', value: 'Luxembourg' },
{ label: 'Madagascar', value: 'Madagascar' },
{ label: 'Malawi', value: 'Malawi' },
{ label: 'Malaysia', value: 'Malaysia' },
{ label: 'Maldives', value: 'Maldives' },
{ label: 'Mali', value: 'Mali' },
{ label: 'Malta', value: 'Malta' },
{ label: 'Marshall Islands', value: 'Marshall Islands' },
{ label: 'Mauritania', value: 'Mauritania' },
{ label: 'Mauritius', value: 'Mauritius' },
{ label: 'Mecklenburg-Schwerin*', value: 'Mecklenburg-Schwerin*' },
{ label: 'Mecklenburg-Strelitz*', value: 'Mecklenburg-Strelitz*' },
{ label: 'Mexico', value: 'Mexico' },
{ label: 'Micronesia', value: 'Micronesia' },
{ label: 'Moldova', value: 'Moldova' },
{ label: 'Monaco', value: 'Monaco' },
{ label: 'Mongolia', value: 'Mongolia' },
{ label: 'Montenegro', value: 'Montenegro' },
{ label: 'Morocco', value: 'Morocco' },
{ label: 'Mozambique', value: 'Mozambique' },
{ label: 'Namibia', value: 'Namibia' },
{ label: 'Nassau*', value: 'Nassau*' },
{ label: 'Nauru', value: 'Nauru' },
{ label: 'Nepal', value: 'Nepal' },
{ label: 'Netherlands, The', value: 'Netherlands, The' },
{ label: 'New Zealand', value: 'New Zealand' },
{ label: 'Nicaragua', value: 'Nicaragua' },
{ label: 'Niger', value: 'Niger' },
{ label: 'Nigeria', value: 'Nigeria' },
{ label: 'North German Confederation*', value: 'North German Confederation*' },
{ label: 'North German Union*', value: 'North German Union*' },
{ label: 'North Macedonia', value: 'North Macedonia' },
{ label: 'Norway', value: 'Norway' },
{ label: 'Oldenburg*', value: 'Oldenburg*' },
{ label: 'Oman', value: 'Oman' },
{ label: 'Orange Free State*', value: 'Orange Free State*' },
{ label: 'Pakistan', value: 'Pakistan' },
{ label: 'Palau', value: 'Palau' },
{ label: 'Panama', value: 'Panama' },
{ label: 'Papal States*', value: 'Papal States*' },
{ label: 'Papua New Guinea', value: 'Papua New Guinea' },
{ label: 'Paraguay', value: 'Paraguay' },
{ label: 'Peru', value: 'Peru' },
{ label: 'Philippines', value: 'Philippines' },
{ label: 'Piedmont-Sardinia*', value: 'Piedmont-Sardinia*' },
{ label: 'Poland', value: 'Poland' },
{ label: 'Portugal', value: 'Portugal' },
{ label: 'Qatar', value: 'Qatar' },
{ label: 'Republic of Genoa*', value: 'Republic of Genoa*' },
{ label: 'Republic of Korea (South Korea)', value: 'Republic of Korea (South Korea)' },
{ label: 'Republic of the Congo', value: 'Republic of the Congo' },
{ label: 'Romania', value: 'Romania' },
{ label: 'Russia', value: 'Russia' },
{ label: 'Rwanda', value: 'Rwanda' },
{ label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
{ label: 'Saint Lucia', value: 'Saint Lucia' },
{ label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
{ label: 'Samoa', value: 'Samoa' },
{ label: 'San Marino', value: 'San Marino' },
{ label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
{ label: 'Saudi Arabia', value: 'Saudi Arabia' },
{ label: 'Schaumburg-Lippe*', value: 'Schaumburg-Lippe*' },
{ label: 'Senegal', value: 'Senegal' },
{ label: 'Serbia', value: 'Serbia' },
{ label: 'Seychelles', value: 'Seychelles' },
{ label: 'Sierra Leone', value: 'Sierra Leone' },
{ label: 'Singapore', value: 'Singapore' },
{ label: 'Slovakia', value: 'Slovakia' },
{ label: 'Slovenia', value: 'Slovenia' },
{ label: 'Solomon Islands, The', value: 'Solomon Islands, The' },
{ label: 'Somalia', value: 'Somalia' },
{ label: 'South Africa', value: 'South Africa' },
{ label: 'South Sudan', value: 'South Sudan' },
{ label: 'Spain', value: 'Spain' },
{ label: 'Sri Lanka', value: 'Sri Lanka' },
{ label: 'Sudan', value: 'Sudan' },
{ label: 'Suriname', value: 'Suriname' },
{ label: 'Sweden', value: 'Sweden' },
{ label: 'Switzerland', value: 'Switzerland' },
{ label: 'Syria', value: 'Syria' },
{ label: 'Tajikistan', value: 'Tajikistan' },
{ label: 'Tanzania', value: 'Tanzania' },
{ label: 'Texas*', value: 'Texas*' },
{ label: 'Thailand', value: 'Thailand' },
{ label: 'Timor-Leste', value: 'Timor-Leste' },
{ label: 'Togo', value: 'Togo' },
{ label: 'Tonga', value: 'Tonga' },
{ label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
{ label: 'Tunisia', value: 'Tunisia' },
{ label: 'Turkey', value: 'Turkey' },
{ label: 'Turkmenistan', value: 'Turkmenistan' },
{ label: 'Tuvalu', value: 'Tuvalu' },
{ label: 'Two Sicilies*', value: 'Two Sicilies*' },
{ label: 'Uganda', value: 'Uganda' },
{ label: 'Ukraine', value: 'Ukraine' },
{ label: 'Union of Soviet Socialist Republics*', value: 'Union of Soviet Socialist Republics*' },
{ label: 'United Arab Emirates, The', value: 'United Arab Emirates, The' },
{ label: 'United Kingdom, The', value: 'United Kingdom, The' },
{ label: 'Uruguay', value: 'Uruguay' },
{ label: 'Uzbekistan', value: 'Uzbekistan' },
{ label: 'Vanuatu', value: 'Vanuatu' },
{ label: 'Venezuela', value: 'Venezuela' },
{ label: 'Vietnam', value: 'Vietnam' },
{ label: 'Württemberg*', value: 'Württemberg*' },
{ label: 'Yemen', value: 'Yemen' },
{ label: 'Zambia', value: 'Zambia' },
{ label: 'Zimbabwe', value: 'Zimbabwe' },

];

const inputHandler = (e) => {
  console.log(e);
  const { value, maxLength } = e.target;
  if (String(value).length >= maxLength) {
    e.preventDefault();
    return;
  }
};

  return (
    <>
        <div className='container'>
          <h2 className="mt-4 text-center">Overseas Mediclaim Policy Form</h2>
          <div className="card col-md-12">
            <div className="card-header bg-info">New Policy</div>
            <div className="card-body">
              <div className='row' >
               <form class="row g-3">
                <div class="col-md-6 form-group">
                  <label for="sub_type">Insurance Sub Type</label>
                  <select className="form-select" aria-label="Group"
                    name='MRType' id='MRType'
                    //value={input.MRType}
                    onChange={handleTariffSelect}
                    >
                    <option key={'Select'} value={0} >Select</option> 
                    <option key={'Business & Holiday'} value={1}>Business & Holiday</option> 
                    <option key={'Employment'} value={2}>Employment</option> 
                    <option key={'Student'} value={3}>Student</option>  
                    
                  </select>
                </div>

                <div className="col-md-6 form-group">
                  <label for="sub_type">OMP Type</label>
                  <select className="form-select" aria-label="Group"
                    name='MRType_2' id='MRType_2'
                    //value={input.MRType_2}
                    onChange={handleTariffSelect}
                    >
                    
                    <option value={0}>Select  </option>
                    <option value={1}>NON-SCHENGEN COUNTRIES  </option>
                    <option value={2}>NON-SCHENGEN COUNTRIES + USA CANADA EXCLUDING SCHENGEN</option>                    
                    <option value={3}>FOR SCHENGEN COUNTRIES ONLY </option>
                    <option value={4}>SCHENGEN COUNTRIES + USA CANADA + WORLDWIDE </option>
                    
                  </select>
                </div>

                <div className="col-md-6 form-group">
                  <label for="travel_days">Travel Days</label>
                  <input type='number' className='form-control' id='Num_Field_1' name='Num_Field_1' maxLength={4}
                    value={input.Num_Field_1}
                    onChange={handleTariffSelect}
                    onKeyUp={inputHandler}
                  />
                </div>

                <div className="col-md-6 form-group">
                  <label for="Date_of_birth">Date of Birth</label>
                  <Form.Group >                    
                      <Form.Control type="date" id="Date_Field_1" name="Date_Field_1" placeholder="Date" 
                        value={input.Date_Field_1}
                        onChange={handleTariffSelect}
                      />
                  </Form.Group>
                </div>

                <div className="col-md-4 form-group">
                  <label for="premium_amount">Premium</label>
                  <input type='number' className='form-control' id='NetPremium' name='NetPremium'
                    value={input.NetPremium}
                    onChange={handleInput}
                    readOnly
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label for="vat_amount">VAT</label>
                  <input type='number' className='form-control' id='VatAmount' name='VatAmount'
                    value={input.VatAmount}
                    onChange={handleInput}
                    readOnly
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label for="total_amount">Total</label>
                  <input type='number' className='form-control' id='MRNetPremium' name='MRNetPremium'
                    value={input.MRNetPremium}
                    onChange={handleInput}
                    readOnly
                  />
                </div>


                <div className="col-md-4 form-group">
                  <label for="gender">Gender</label>
                  <select className="form-select" aria-label="Group"
                    name='Text_Field_1' id='Text_Field_1'
                    value={input.Text_Field_1}
                    onChange={handleInput}
                    >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="col-md-4 form-group">
                  <label for="first_name">First Name</label>
                  <input type='text' className='form-control' id='Text_Field_2' name='Text_Field_2'
                    value={input.Text_Field_2}
                    onChange={handleInput}
                    
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label for="last_name">Last Name</label>
                  <input type='text' className='form-control' id='Text_Field_3' name='Text_Field_3'
                    value={input.Text_Field_3}
                    onChange={handleInput}
                    
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label for="father_name">Father's Name</label>
                  <input type='text' className='form-control' id='Text_Field_4' name='Text_Field_4'
                    value={input.Text_Field_4}
                    onChange={handleInput}
                    
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label for="address">Address</label>
                  <textarea  className='form-control' id='Text_Field_5' name='Text_Field_5'
                    value={input.Text_Field_5}
                    onChange={handleInput}
                    
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label for="mobile_no">Mobile No</label>
                  <input type='text' className='form-control' id='Text_Field_6' name='Text_Field_6'
                    value={input.Text_Field_6}
                    onChange={handleInput}
                    
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label for="email">Email</label>
                  <input type='email' className='form-control' id='Text_Field_7' name='Text_Field_7'
                    value={input.Text_Field_7}
                    onChange={handleInput}
                  
                    placeholder='example@domain.com'
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label for="Occupation">Occupation</label>
                  <input type='text' className='form-control' id='Text_Field_8' name='Text_Field_8'
                    value={input.Text_Field_8}
                    onChange={handleInput}
                  
                    placeholder='Occupation'
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label for="Passport_No">Passport No</label>
                  <input type='text' className='form-control' id='Text_Field_9' name='Text_Field_9'
                    value={input.Text_Field_9}
                    onChange={handleInput}
                  
                    placeholder='Passport No'
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label for="Date_of_birth">Period From</label>
                  <Form.Group >                    
                      <Form.Control type="date" id="PeriodFrom" name="PeriodFrom" placeholder="Date" min={new Date().toISOString().substring(0, 10)}
                        value={input.PeriodFrom }
                        onChange={handleInput}
                      />
                  </Form.Group>
                </div>
                <div className="col-md-4 form-group">
                  <label for="Date_of_birth">Period To</label>
                  <Form.Group >                    
                      <Form.Control type="date" id="PeriodTo" name="PeriodTo" placeholder="Date"  min={new Date().toISOString().substring(0, 10)} 
                        value={input.PeriodTo }
                        
                        onChange={handleInput}
                        readOnly
                      />
                  </Form.Group>
                </div>

                <div className="col-md-4 form-group">
                  <label for="physician">Physicion Name & Mobile Number</label>
                  <input type='text' className='form-control' id='Text_Field_10' name='Text_Field_10'
                    value={input.Text_Field_10}
                    onChange={handleInput}
                  
                    placeholder='Physition'
                  />
                </div>

                <div className="col-md-4 form-group">
                  <label for="Destination">Destination</label>
                  {/* <input type='text' className='form-control' id='Text_Field_11' name='Text_Field_11' required
                    value={input.Text_Field_11}
                    onChange={handleInput}
                  
                    placeholder='Destination'
                  /> */}
                  
                  <CreatableSelect 
                    isClearable 
                    className="basic-single"
                    classNamePrefix="select"
                    
                    isDisabled={false}
                    isLoading={false}
                    
                    isRtl={false}
                    isSearchable={true}
                    name="color"
                    options={colourOptions}
                    onChange={handleChange}
                    //value={input.Text_Field_11}
                  />

                </div>
                <div className="col-md-4 form-group"></div>



                <div className="col-md-12 p-3 text-center">
                  <button type="button" className="btn btn-primary mx-2 col-md-2" onClick={SaveData} ><i className="fa-light fa-floppy-disk"></i> Submit</button>
                  <button type="button" className="btn btn-primary mx-2 col-md-2" onClick={DownloadFile}  ><i className="fa-light fa-floppy-disk"></i> Download</button>
                  <ToastContainer />
                </div>

               
              </form>
                
              </div>
            </div>
            
          </div>
        </div>
    </>
  )
}
