import React from 'react'
import { Link } from 'react-router-dom'

export default function SideBar() {

    
  return (
        
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">Overseas Mediclaim Policy</div>
                            <a className="nav-link" href="/">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Dashboard
                            </a>
                            <div className="sb-sidenav-menu-heading">Interface</div>
                            
                                                      
                              
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseMotor" aria-expanded="false" aria-controls="collapseMotor">
                                <div className="sb-nav-link-icon"><i className="fa fa-car" aria-hidden="true"></i></div>
                                Motor
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="collapseMotor" aria-labelledby="headingThree" data-bs-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">   
                                    <Link className="nav-link p-0 m-1" to="/motor/certificate" relative="path"> Motor Certificate</Link>     
                                    
                                </nav>
                                
                            </div> 
                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseMiscellanuous" aria-expanded="false" aria-controls="collapseMiscellanuous">
                                <div className="sb-nav-link-icon"><i className="fa-regular fa-snowflake" aria-hidden="true"></i></div>
                                Miscellanuous
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </a>
                            <div className="collapse" id="collapseMiscellanuous" aria-labelledby="headingThree" data-bs-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">   
                                    <Link className="nav-link p-0 m-1" to="/Miscellaneous/OverseasMediclaimPolicy" relative="path"> Overseas Mediclaim</Link>     
                                    
                                </nav>
                                
                            </div>                          
                            
                            
                            
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Logged in as:</div>
                        
                    </div>
                </nav>
            </div>
        
  )
}
